<template>
  <div class='flex flex-col-reverse items-stretch lg:flex-row min-h-screen'>
    <div
      class='w-full lg:w-80 px-4 lg:px-16 py-16 text-gray-50 flex flex-col items-start justify-start'
      :style='backgroundStyle'>
      <h1 class='text-3xl font-light'>사전등록</h1>
    </div>
    <div class='p-8 lg:p-16 overflow-y-auto h-screen flex-grow'>
      <div class='max-w-2xl'>
        <h1 class='text-3xl font-semibold mb-8'>사전등록 확인</h1>
        <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
          <h2 
            class='text-xl font-semibold'
            :class='alignTextClass'>
            사전등록이 완료되었습니다!
          </h2>
          <ul v-if='showBillingInfo'
            class='list-disc mt-8'>
            <li class='py-1'>등록비: {{feeAmount}}만원</li>
            <li class='py-1'>등록비 납부는 온라인 사전등록을 완료하신 후 반드시 등록자 성함으로 송금해 주시고 현금영수증은 성함, 입금일, 현금영수증 발행번호를 작성하여 학회 이메일 (<a href='mailto:ortho5@koa.or.kr' class='underline' :style='themeTextColorStyle'>ortho5@koa.or.kr</a>)로 요청하시기 바랍니다.</li>
            <li class='py-1'>학회 계좌 정보 : <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>KB국민은행 269101-04-008077</span>, 예금주 대한정형외과학회</li>
          </ul>
        </div>
        <router-link :to='{name: "Home"}' class='block mt-8 w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈 으로가기</router-link>
      </div>
    </div>

  </div>

</template>

<script>
// import { mapFields }              from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SignupConfirmation',
  computed: {
    ...mapState('users', [
      'profile',
      'membershipData',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'themeTextColorStyle',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    showBillingInfo () {
      return !this.membershipData.isKoaMemberOver65
    },
    alignTextClass () {
      return this.showBillingInfo ? 'text-left' : 'text-center'
    },
    feeAmount () {
      return (this.membershipData.isKoaMember === 'isMember')  ? 2 : 3
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
